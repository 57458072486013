import React, {useState, useEffect} from "react";
import Img from "gatsby-image";
import ImageProtect from '../ui/ImageProtect'
import Div100vh from 'react-div-100vh'
import { getConnectionSpeed } from '../../core/utils'

const ImagesFluid = ({ input, context }) => {
  // console.log(input.primary)
  const [isMobile, setIsMobile] = useState(false)
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    const _isMobile = window.innerWidth <= 768
    setIsMobile(_isMobile)
    
    // const _connectionSpeed = getConnectionSpeed()
    // if(_connectionSpeed && _connectionSpeed !== "high-bandwidth")setFadeIn(true)
  }, [])

  useEffect(() => {
    if(isMobile)setFadeIn(true)
  }, [isMobile])

  // console.log(fadeIn)

  const {
    gutter_size,
    align,
    padding,
    padding_mobile,
    background
  } = input.primary

  const _getAlign = () => {
    switch(align){
      case "top": return "flex-start";
      case "center": return "center";
      case "bottom": return "flex-end";
      default: return "flex-start"
    }
  }

  const _getPadding = () => {
    // console.log(padding)
    let _padding = isMobile ? padding_mobile : padding
    if(!_padding)return "0 10vw"

    const parts = _padding.replace(/%/g, "").split(" ")
    // console.log(parts)
    return isMobile 
    ? `${_getVhVal(parts[0])}px ${parts[1]}vw ${_getVhVal(parts[2])}px ${parts[3]}vw ` 
    :`${parts[0]}vh ${parts[1]}vw ${parts[2]}vh ${parts[3]}vw ` 
  
  }
  
  const _getHeight = (size, size_mobile) => {
    // console.log("_getHeightMobile",_getHeightMobile(size_mobile))
    return isMobile ? _getHeightMobile(size_mobile)+"px" : size+"vh"
  }
  const _getHeightMobile = (size_mobile) => {
    // console.log(size_mobile, _getBottomBarHeight())
    if(typeof window !== 'undefined'){
      return _getVhVal(size_mobile)
    }
  }

  const _getWidth = (image, size, size_mobile) => {
    // console.log(size, size_mobile)
    let _size = isMobile && size_mobile ? _getHeightMobile(size_mobile) : size
    const width = _size ? (_size * image.fluid.aspectRatio) : 50
    return isMobile ? `${width}px` : `${width}vh`;
  }

  const _getVhVal = (val) => {
    if(!isMobile)return val

    const screenHeight = window.screen.availHeight - _getBottomBarHeight()
    return (val * screenHeight) / 100
  }

  const _getBottomBarHeight = () => {
    return typeof window !== 'undefined' ? (window.screen.availHeight - window.innerHeight) : 0
  }

  const _getGutter = () => gutter_size ? gutter_size/2 : 0

  const _getBg = () => background ? background : 'transparent'


  const hasPadding = padding !== '' ? 'has-padding' : ''

  return (
    <div className={`slice images-fluid is-align-${align} ${hasPadding}`} style={{
      backgroundColor: _getBg()
    }}>
      <Div100vh>
        <div className="inner h100" style={{
          padding: _getPadding()
        }}>
          
          <div className="x h100" style={{
            marginLeft: -_getGutter()+"px",
            marginRight: -_getGutter()+"px",
          }}>

            {input.items.map( ({image, size, size_mobile}, i) => (
              <div className={"col"} key={i} style={{
                height: _getHeight(size, size_mobile),
                alignSelf:  _getAlign(),
                paddingLeft: _getGutter()+"px",
                paddingRight: _getGutter()+"px"
              }}>
                <Img
                  fluid={image.fluid}
                  Tag="figure"
                  fadeIn={context === "scroll"}
                  // loading={!fadeIn ? 'auto' : 'lazy'}
                  // loading="auto"
                  style={{
                    // height: `${size}vh`,
                    height: _getHeight(size, size_mobile),
                    width: _getWidth(image, size, size_mobile)
                  }}
                />
                <ImageProtect input={image} />
              </div>
            ))}
          </div>
        </div>
      </Div100vh>
    </div>
  );
};

export default ImagesFluid;
