import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import ImageProtect from '../ui/ImageProtect'
import Div100vh from 'react-div-100vh'

const Images = ({ input, context }) => {
  // console.log(input)
  const {
    gutter_size,
    align,
    background
  } = input.primary
// console.log(gutter_size)

  const [isMobile, setIsMobile] = useState(undefined)

  useEffect(() => {
    const _isMobile = 'ontouchstart' in window && window.innerWidth <= 768
    setIsMobile(_isMobile)
    console.log(_isMobile)
    // if('ontouchstart' in window && window.innerWidth <= 768){
    //   setIsMobile(true)
    // }else{
    //   setIsMobile(false)
    // }
  }, [])

  const _getAlign = () => {
    switch(align){
      case "left": return "start-xs";
      case "right": return "end-xs";
      case "center": return "middle-xs h100";
      default: return "middle-xs"
    }
  }

  const _getGutter = () => {
    return gutter_size ? gutter_size/2 : 0
  }

  const _getBg = () => {
    return background ? background : 'transparent'
  }
  
  const isSoloLandscape = input.items.length === 1 && input.items[0].image.fluid.aspectRatio > 1
  // console.log(input.items[0].image.fluid.aspectRatio)
  return (
    <div className="slice images" style={{
      backgroundColor: _getBg()
    }}>
      <Div100vh>
      <div className={`container-fluid ${align === 'center' ? 'h100' : ''}`}>
        <div className={`row center-xs ${_getAlign()} ${isSoloLandscape ? 'h100' : ''}`} style={{
          marginLeft: -_getGutter()+"px",
          marginRight: -_getGutter()+"px",
        }}>
          <div className="col-md-3 hidden-xs"></div>

          {isSoloLandscape &&
            <div className="col-md-6 col-xs-12 h100 images-single" style={{
              paddingLeft: _getGutter()+"px",
              paddingRight: _getGutter()+"px",
            }}>
              <Img
                fluid={input.items[0].image.fluid}
                // Tag="figure"
                fadeIn={false}
                style={{
                  height: isMobile ? 'auto' : '100%',
                  verticalAlign: 'top'
                }}
              />
              <ImageProtect />
            </div>
          }
          {!isSoloLandscape &&
          input.items.map( ({image, column}, i) => (
            <div className={`col-md-${column ? column : '3'} col-xs-6 images-double`} key={i} style={{
              paddingLeft: _getGutter()+"px",
              paddingRight: _getGutter()+"px",
            }}>
              <Img
                fluid={image.fluid}
                Tag="figure"
                // durationFadeIn={150}
                fadeIn={false}
                style={{
                  verticalAlign: 'top'
                }}
              />
              <ImageProtect />
              {/* {gutter_size} */}
            </div>
          ))
          }
          <div className="col-md-3 hidden-xs"></div>
        </div>
      </div>
      </Div100vh>
    </div>
  );
};

export default Images;
