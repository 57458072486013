import React, {useState, useRef} from 'react';
import {_getLocalizedText} from '../../../core/utils'
// import { RichText } from "prismic-reactjs"
import PubSub from 'pubsub-js'
import "./style.scss"
import { useEffect } from 'react';

const ImageProtect = ({input}) => {
  const [open, setOpen] = useState(false);
  const [isAnnJo, setIsAnnJo] = useState(false)
  // const [positions, setPositions] = useState({x: 0, y: 0});
  const wrapper = useRef()
  const dialog = useRef()

  useEffect(() => {
    const tokenA = PubSub.subscribe("IS_ANN_JO", (e, d) => {
      setIsAnnJo(d)
    })

    const tokenB = PubSub.subscribe("IMAGE_PROTECT_OPEN", (e, d) => {
      console.log(e, d)
      if(d !== input.url){
        setOpen(false)
      }
    })

    return () => {
      PubSub.unsubscribe(tokenA)
      PubSub.unsubscribe(tokenB)
    }
  }, []);

  useEffect(() => {
    if(open && input && input.url)PubSub.publish("IMAGE_PROTECT_OPEN", input.url)

  }, [open])

  const _onContextMenu = (e) => {
    e.preventDefault()
    _updatePosition(e.clientX, e.clientY)
    document.addEventListener("click", _onDocumentClick)
    setOpen(!open)
  }

  const _onDocumentClick = () => {
    document.removeEventListener("click", _onDocumentClick)
    setOpen(false)
  }

  const _updatePosition = (clientX, clientY) => {
    const {x, y} = wrapper.current.getBoundingClientRect()
    // const {width, height} = dialog.current.getBoundingClientRect()
    const left = clientX - x
    const top = clientY - y
    dialog.current.style.left = left+"px"
    dialog.current.style.top = top+"px"
  }

  return (
    <div className="image-protect" onClick={() => setOpen(false)} onContextMenu={_onContextMenu} ref={wrapper}>
      <div className={`dialog fS ${open ? 'is-active' : ''}`} ref={dialog} >
        <div dangerouslySetInnerHTML={{
          __html: isAnnJo ? _getLocalizedText("imageProtectMessageAnnJo") : _getLocalizedText("imageProtectMessage")
        }}></div>
       
      </div>
    </div>
  );
};

export default ImageProtect;