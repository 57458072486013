import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import BgImg from "gatsby-background-image";
// import { WrapperContext } from "../Layout"
import Div100vh from 'react-div-100vh'
import ImageProtect from '../ui/ImageProtect'
import { getConnectionSpeed } from '../../core/utils'

const FullImage = ({ input, context }) => {
  // const {template} = useContext(WrapperContext)
  // const {template} = _WrapperContext
  // console.log(context)
  const [isMobile, setIsMobile] = useState(false)
  const [fadeIn, setFadeIn] = useState(false)
  const [height, setHeight] = useState("100vh")
  
  useEffect(() => {
    const _isMobile = window.innerWidth <= 768
    setIsMobile(_isMobile)
    // const _connectionSpeed = getConnectionSpeed()
    // if(_connectionSpeed && _connectionSpeed !== "high-bandwidth")setFadeIn(true)
  }, [])

  useEffect(() => {
    if(isMobile){
      setFadeIn(true)
      const height = window.innerHeight
      setHeight(height+"px")
    }
  }, [isMobile])

  // console.log(fadeIn)
  return (
    <div className={"slice full-image"}>
      <Div100vh>
      {context === "scroll" &&
      <Img
        fluid={input.primary.image.fluid}
        fadeIn={true}
        style={{ 
          height: height,
          width: `calc((${height} * ${input.primary.image.fluid.aspectRatio})`
        }}
      />
      }
      {context === "slider" &&
      <BgImg
        fluid={input.primary.image.fluid}
        fadeIn={false}
        style={{ 
          height: height,
          width: `100vw`
        }}
      />
      }
      <ImageProtect input={input.primary.image} />
      </Div100vh>
    </div>
  )
}

export default FullImage;
