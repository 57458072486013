import React, { useEffect, useRef, useState } from 'react';
// import HorizontalScroll from '../lib/react-scroll-horizontal'
// import Draggable from 'react-draggable';
import {throttle, debounce} from 'throttle-debounce'
import Div100vh from 'react-div-100vh'
// import { Motion, spring, presets } from 'react-motion'
import PubSub from 'pubsub-js'
// import Texte from "./Texte";
import Images from "./Images";
import ImagesFluid from "./ImagesFluid";
import FullImage from "./FullImage";

const SlicesScroller = ({input}) => {
  // const {data} = props
  // input.filter(el => el !== null)
  const [isMobile, setIsMobile] = useState(undefined)
  const scroller = useRef();

  useEffect(() => {
    if('ontouchstart' in window && window.innerWidth <= 768){
      setIsMobile(true)
      scroller.current.addEventListener('scroll', _onScroll)
    }else{
      setIsMobile(false)
    }
    scroller.current.addEventListener('wheel', _onWheel)

    return () => {
      scroller.current.removeEventListener('wheel', _onWheel)
      scroller.current.removeEventListener('scroll', _onScroll)
    }
  }, [])

  // useEffect(() => {
  //   if(isMobile){
  //     scroller.current.addEventListener('scroll', _onScroll)
  //   }
  //   return () => scroller.current.removeEventListener('scroll', _onScroll)
  // }, [isMobile])

  const _renderSlices = (slices) => {
    // console.log(slices)
    const _slice = slices.map((slice, i) => {
      if(slice === null)return null
      // console.log(slice.slice_type)
      switch (slice.slice_type) {
        case "images":
          return <Images key={i} input={slice} context="scroll" />;
        case "images_fluid":
          return <ImagesFluid key={i} input={slice} context="scroll"/>;
        case "full_image":
          return <FullImage key={i} input={slice} context="scroll" />;
        default:
          return null;
      }
    });

    return _slice;
  }

  const _onWheel = (e) => {
    if(isMobile)return
    // console.log(e)
    // const rawData = e.deltaX ? e.deltaX : e.wheelDelta
    const rawData = e.wheelDelta
    const mouseX = Math.floor(rawData)
    // console.log(mouseX, scroller)
    scroller.current.scrollLeft -= mouseX;
    
    //detect end of scroll > _onScrollEnd()
    if (scroller.current.offsetWidth + scroller.current.scrollLeft >= scroller.current.scrollWidth) {
      // console.log("_onWheel end")
      _onScrollEndDebounce()
    }

    e.preventDefault();
  }
  
  const _onScroll = (d) => {
    // console.log("_onScroll")
    if (scroller.current.offsetWidth + scroller.current.scrollLeft >= scroller.current.scrollWidth) {
      console.log("_onScroll end")
      _onScrollEndDebounce()
    }
  }

  const _onScrollEndDebounce = debounce(400, false, () => {
    // console.log('___onScrollEndDebounce:');
    PubSub.publish("SCROLL_END")
  });

  // const _onScrollEnd = () => {
  //   // console.log("_onScrollEnd")
  //   PubSub.publish("SCROLL_END")
  // }
  // console.log(isMobile)
  return (
    <div className={"slices slices-scroller"}>
      <Div100vh>
        <div className="h100 x xwn scrollX" ref={scroller}>
          {input && _renderSlices(input)}
        </div>
      </Div100vh>
    </div>
  );
};

export default SlicesScroller;
